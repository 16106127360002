import { Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useEffect, useState } from "react";
import {
  useGetProposalDetailsQuery,
  useGetRelationMasterQuery,
  useUpdateProposalFormData,
  useGetBankDetailsByIFSC,
} from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyNumbers,
  toCapitalize,
} from "../../../../utils/inputUtils";
import DateComp from "../InputComponents/DateComp";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setProposalData } from "../../../../modules/proposal.slice";
import HeartLoader from "../../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import InputSideEffect from "../InputComponents/InputSideEffect";
import CachedIcon from "@mui/icons-material/Cached";
import BankTextInput from "../InputComponents/BankTextInput";
import YesNo from "../InputComponents/YesNo";

const NomineeForm = () => {
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { isCKYC, isPortability, product } = useGetProposalDetailsQuery();
  const { relations } = useGetRelationMasterQuery();
  const [nominee_ifsccode, setIfscCode] = useState(
    proposalData?.nominee_details?.nominee_ifsccode || ""
  );
  const { saveForm, isSaved, isSaving } = useUpdateProposalFormData(() => {
    if (!isPortability) {
      navigate({
        pathname: "/proposal-summary",
        search: window.location.search,
      });
    }
  });
  const navigate = useNavigate();
  const self_dob = proposalData?.proposer_details?.dob;
  const {
    mutate: fetchBankDetails,
    data: bankDetails,
    isLoading,
  } = useGetBankDetailsByIFSC();
  const dispatch = useDispatch();

  useEffect(() => {
    if (nominee_ifsccode.length === 11) {
      fetchBankDetails(nominee_ifsccode);
    }
  }, [nominee_ifsccode, fetchBankDetails]);

  if (activeProposal !== (isCKYC ? 4 : 3)) return null;

  return (
    <>
      <Formik
        initialValues={{
          nominee_name: proposalData?.nominee_details?.nominee_name || "",
          nominee_relation:
            proposalData?.nominee_details?.nominee_relation || "",
          nominee_relation_data:
            proposalData?.nominee_details?.nominee_relation_data || "",
          nominee_dob: proposalData?.nominee_details?.nominee_dob || "",
          nominee_contact: proposalData?.nominee_details?.nominee_contact || "",
          others_nominee_relation:
            proposalData?.nominee_details?.others_nominee_relation || "",
          nominee_bankAccountNo:
            proposalData?.nominee_details?.nominee_bankAccountNo || "",
          nominee_ifsccode:
            proposalData?.nominee_details?.nominee_ifsccode || "",
          nominee_bank: proposalData?.nominee_details?.nominee_bank || "",
          nominee_branchName:
            proposalData?.nominee_details?.nominee_branchName || "",
          appointee_name: proposalData?.nominee_details?.appointee_name || "",
          appointee_relation:
            proposalData?.nominee_details?.appointee_relation || "",
          appointee_dob: proposalData?.nominee_details?.appointee_dob || "",
          nominee_email: proposalData?.nominee_details?.nominee_email || "",
        }}
        validationSchema={yup.object().shape({
          nominee_name: yup.string().required("Nominee name is required"),
          nominee_relation: yup
            .string()
            .required("Nominee relation is required"),
          nominee_dob: yup
            .string()
            .required("Nominee dob is required")
            .test(
              "nominee_dob",
              "Parents age must be 18 years older",
              function (val) {
                if (
                  self_dob &&
                  (this.parent.nominee_relation_data?.toLowerCase() ===
                    "father" ||
                    this.parent.nominee_relation_data?.toLowerCase() ===
                      "mother")
                ) {
                  return (
                    moment(self_dob, "YYYY-MM-DD").diff(
                      moment(val, "YYYY-MM-DD"),
                      "years"
                    ) >= 18
                  );
                }
                return true;
              }
            ),
          nominee_contact: yup.string().required("Nominee contact is required"),
        })}
        onSubmit={async (values) => {
          saveForm({
            nominee_details: values,
            from_screen: "nominee",
          });
          dispatch(
            setProposalData({
              nominee_details: {
                ...values,
                nominee_ifsccode,
                nominee_bank: bankDetails?.BANK,
                nominee_branchName: bankDetails?.BRANCH,
              },
            })
          );
        }}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              {isSaving && <HeartLoader message={""} />}
              {/* Nominee Fields */}
              <Grid item xs={12} md={6}>
                <>
                  <TextInput
                    label="Nominee Name"
                    name="nominee_name"
                    onInput={(e) => {
                      allowOnlyAlphabets(e);
                      toCapitalize(e);
                    }}
                  />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[values.nominee_relation]}
                  effect={() => {
                    setFieldValue(
                      "nominee_relation_data",
                      relations?.find(
                        (item) => item.OPTION_KEY === values.nominee_relation
                      )?.OPTION_VAL || ""
                    );
                  }}
                >
                  <Select
                    label="Nominee's Relation to Proposer"
                    name="nominee_relation"
                    options={relations || []}
                  />
                </InputSideEffect>
              </Grid>
              {values.nominee_relation_data === "Others" &&
                product?.INSURANCE_SLUG === "niva_bupa" && (
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Nominee's Relation"
                      name="others_nominee_relation"
                      maxLength={30}
                      onInput={(e) => {
                        allowOnlyAlphabets(e);
                      }}
                    />
                  </Grid>
                )}

              <Grid item xs={12} md={6}>
                <DateComp
                  label="Nominee Dob"
                  name="nominee_dob"
                  minDate={moment().subtract(60, "years")}
                  maxDate={moment()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Nominee Contact"
                  name="nominee_contact"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name="nominee_email"
                  label="Nominee Email"
                  maxLength={30}
                />
              </Grid>
              {moment().diff(moment(values.nominee_dob, "YYYY-MM-DD"), "year") <
                18 && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Appointee Name"
                      name="appointee_name"
                      onInput={(e) => {
                        allowOnlyAlphabets(e);
                        toCapitalize(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[values.appointee_relation]}
                      effect={() => {
                        setFieldValue(
                          "appointee_relation_data",
                          relations?.find(
                            (item) =>
                              item.OPTION_KEY === values.appointee_relation
                          )?.OPTION_VAL || ""
                        );
                      }}
                    >
                      <Select
                        label="Appointee's Relation to Nominee"
                        name="appointee_relation"
                        options={relations || []}
                      />
                    </InputSideEffect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateComp
                      label="Appointee Dob"
                      name="appointee_dob"
                      minDate={moment().subtract(60, "years")}
                      maxDate={moment().subtract(18, "years")}
                    />
                  </Grid>
                </>
              )}
              {product?.INSURANCE_SLUG === "niva_bupa" && (
                <>
                  <Grid item xs={12}>
                    <Typography color="text.secondary" fontWeight="500">
                      Nominee Bank Details
                      {isLoading && (
                        <div className="center">
                          <CachedIcon className="loading-icon" />
                        </div>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name="nominee_bankAccountNo"
                      label="Bank Account Number"
                      maxLength={30}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BankTextInput
                      name="nominee_ifsccode"
                      label="IFSC Code"
                      maxLength={11}
                      externalValue={nominee_ifsccode}
                      onChange={(e) => setIfscCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BankTextInput
                      label="Bank Name"
                      name="nominee_bank"
                      externalValue={bankDetails?.BANK}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BankTextInput
                      name="nominee_branchName"
                      label="Branch Name"
                      externalValue={bankDetails?.BRANCH}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                  sx={{
                    background: "#FF6024",
                    "&:hover": { background: "#f26d02" },
                  }}
                >
                  {isPortability
                    ? "Proceed to Portability"
                    : "Proceed to Review & Pay"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

const styles = `
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.loading-icon {
font-size: 24px; /* Adjust size as needed */
animation: spin 1s linear infinite;
}
.center {
display: flex;
justify-content: center;
align-items: center;
}
`;

export default NomineeForm;

export const bankAccountType = [
  {
    OPTION_KEY: "Savings",
    OPTION_VAL: "Savings",
  },
  {
    OPTION_KEY: "Current",
    OPTION_VAL: "Current",
  },
];
