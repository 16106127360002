import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import {
  useUploadCignaDocMutation,
  useVerifyCareCKYC,
} from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import { allowOnlyPanCard, regex } from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import { Check, Close } from "@mui/icons-material";

const addressProofOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Passport" },
  { OPTION_KEY: "2", OPTION_VAL: "Voter Id" },
  { OPTION_KEY: "3", OPTION_VAL: "Driving License" },
  { OPTION_KEY: "7", OPTION_VAL: "Pan" },
];

const CKYCCareForm = () => {
  const { saveForm, isSaving } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { upload } = useUploadCignaDocMutation();
  const { verifyCareCKYC, isVerifyCareCKYC } = useVerifyCareCKYC();

  const handleVerifyPan = async () => {
    const { data } = await verifyCareCKYC({
      pan_no: proposer_details?.pan_no,
      dob: proposer_details?.dob,
    });
    dispatch(
      setProposalData({
        ...proposalData,
        ckyc_details: {
          ...proposalData?.ckyc_details,
          ...data?.return_data,
          status: data?.status,
        },
      })
    );
  };

  const handleUploadFiles = async (values) => {
    try {
      const proposer_address_proof = values?.proposer_address_proof;
      const proposer_identity_proof = values?.proposer_identity_proof;

      const uploadResponse = await upload({
        proposer_address_proof,
        proposer_identity_proof,
      });

      const { return_data } = uploadResponse;

      dispatch(
        setProposalData({
          ...proposalData,
          ckyc_details: {
            ...proposalData?.ckyc_details,
            ...values,
            ...return_data,
          },
        })
      );

      return return_data;
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  const proposer_details = proposalData?.proposer_details || {};
  const ckyc_details = proposalData?.ckyc_details || {};

  const initialValues = {
    pan_no: proposer_details?.pan_no || "",
    address_document_type: ckyc_details?.address_document_type || "",
    identity_document_type: ckyc_details?.identity_document_type || "",
    proposer_address_proof: ckyc_details?.proposer_address_path || "",
    proposer_identity_proof: ckyc_details?.proposer_identity_path || "",
    proposer_address_proof_url: "",
    proposer_identity_proof_url: "",
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          pan_no: yup
            .string()
            .required("PAN Number is required")
            .matches(regex.pan_no, "Please Enter a valid PAN Number"),
          address_document_type: yup.string().when("status", {
            is: 500,
            then: yup.string().required("Address Proof is required"),
          }),
          proposer_address_proof: yup.mixed().when("address_document_type", {
            is: (val) => val && val !== "",
            then: yup.mixed().required("Please upload Address Proof"),
          }),
          identity_document_type: yup.string().when("status", {
            is: 500,
            then: yup.string().required("Identity Proof is required"),
          }),
          proposer_identity_proof: yup.mixed().when("identity_document_type", {
            is: (val) => val && val !== "",
            then: yup.mixed().required("Please upload Identity Proof"),
          }),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const uploadResponse = await handleUploadFiles(values);

            await saveForm({
              ckyc_details: {
                ...ckyc_details,
                ...values,
                ...uploadResponse,
              },
              from_screen: "ckyc",
            });
          } catch (error) {
            console.error("Submission error:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ setFieldValue, values, submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextInput
                  label="Pan No."
                  name="pan_no"
                  onInput={allowOnlyPanCard}
                  readOnly
                />
              </Grid>

              <Grid item md={6} xs={12}>
                {ckyc_details?.verification_status ? (
                  <Chip
                    sx={{ mt: "36px" }}
                    variant="outlined"
                    label="Verified"
                    icon={<Check />}
                    color="success"
                  />
                ) : (
                  <Button
                    onClick={handleVerifyPan}
                    disabled={isVerifyCareCKYC}
                    variant="contained"
                    sx={{
                      mt: "32px",
                      background: "#FF6024",
                      "&:hover": { background: "#f26d02" },
                    }}
                    type="button"
                  >
                    {isVerifyCareCKYC ? (
                      <CircularProgress size="30px" />
                    ) : (
                      "Verify PAN No."
                    )}
                  </Button>
                )}
              </Grid>

              {ckyc_details?.status === 500 && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="address_document_type"
                      options={addressProofOptions}
                      label="Address Proof"
                      value={values.address_document_type}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values?.proposer_address_proof ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          fontSize="16px"
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          View Address Document
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          padding="1px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                        >
                          <Box
                            flexGrow={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            padding="4px"
                          >
                            <a
                              href={
                                ckyc_details.proposer_address_path
                                  ? ckyc_details.proposer_address_path
                                  : values.proposer_address_proof_url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {values.proposer_address_proof.name ||
                                "View Uploaded Address Proof"}
                            </a>
                          </Box>
                          <Tooltip title="Clear">
                            <IconButton
                              onClick={() => {
                                URL.revokeObjectURL(
                                  values.proposer_address_proof_url,
                                  ""
                                );
                                setFieldValue("proposer_address_proof", "");
                                setFieldValue("proposer_address_proof_url", "");
                              }}
                              aria-label="clear"
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <TextInput
                        type="file"
                        name="proposer_address_proof"
                        label="Upload Address Proof Document"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setFieldValue("proposer_address_proof", file);
                            setFieldValue(
                              "proposer_address_proof_url",
                              URL.createObjectURL(file)
                            );
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="identity_document_type"
                      options={addressProofOptions}
                      label="Identity Proof"
                      value={values.identity_document_type}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.proposer_identity_proof ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          fontSize="16px"
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          View identity Document
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          padding="1px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                        >
                          <Box
                            flexGrow={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            padding="4px"
                          >
                            <a
                              href={
                                ckyc_details.proposer_identity_path ||
                                values.proposer_identity_proof_url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {values.proposer_identity_proof.name ||
                                "View Uploaded identity Proof"}
                            </a>
                          </Box>
                          <Tooltip title="Clear">
                            <IconButton
                              onClick={() => {
                                URL.revokeObjectURL(
                                  values?.proposer_identity_proof_url,
                                  ""
                                );
                                setFieldValue(
                                  "proposer_identity_proof_url",
                                  ""
                                );
                                setFieldValue("proposer_identity_proof", "");
                              }}
                              aria-label="clear"
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <TextInput
                        type="file"
                        name="proposer_identity_proof"
                        label="Upload Identity Proof Document"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setFieldValue("proposer_identity_proof", file);
                            setFieldValue(
                              "proposer_identity_proof_url",
                              URL.createObjectURL(file)
                            );
                          }
                        }}
                      />
                    )}
                  </Grid>
                </>
              )}

              {(ckyc_details?.verification_status ||
                (values.proposer_identity_proof &&
                  values.proposer_address_proof)) && (
                <Grid
                  item
                  xs={12}
                  justifyContent="center"
                  display={"flex"}
                  my={1}
                >
                  <Box sx={{ position: "relative" }}>
                    <Button
                      size={isMobile ? "medium" : "large"}
                      variant="contained"
                      disabled={isSaving}
                      onClick={submitForm}
                      sx={{
                        background: "#FF6024",
                        "&:hover": { background: "#f26d02" },
                      }}
                    >
                      {isSaving ? (
                        <CircularProgress size="30px" />
                      ) : (
                        "Proceed to Insurer Details"
                      )}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCCareForm;
