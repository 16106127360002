import { Alert, Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  setActiveProposal,
  setProposalData,
} from "../../../../modules/proposal.slice";
import * as yup from "yup";
import { useGetHealthProductByIdQuery } from "../../../../services/Landing.service";
import {
  useGetOccupationQuery,
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
} from "../../../../services/Proposal.service";
import { genderOptions } from "../../options.utils";
import FormLabel from "../FormLabel";
import DateComp from "../InputComponents/DateComp";
import Height from "../InputComponents/Height";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import Toggle from "../InputComponents/Toggle";
import {
  allowOnlyAlphabets,
  allowOnlyNumbers,
  toCapitalize,
} from "../../../../utils/inputUtils";
import moment from "moment";
import { MembersInterface } from "../../../../modules/landing.slice";
import InputSideEffect from "../InputComponents/InputSideEffect";
import { useRef, useState } from "react";
import MedPractSection from "./MedPractSection/MedPractSection";
import HeartLoader from "../../../../components/Loader/Loader";

const InsurerForm = () => {
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const [showMedPractWarning, setShowMedPractWarning] = useState(false);

  const { members, product, isProposalLoading, isCKYC, quote_data } =
    useGetProposalDetailsQuery();
  const { saveForm, isSaving } = useUpdateProposalFormData();
  const { occupation_list } = useGetOccupationQuery();
  const initialData = (gender: "male" | "female" | undefined) => {
    return {
      first_name: "",
      last_name: "",
      gender: gender,
      dob: "",
      feet: "",
      inches: "",
      weight: "",
      occupation: "",
      ABHA_ID: "",
      is_medical_practitioner: "N",
      MD_PRACTIONARY_ID: "",
      COUNCIL_NAME: "",
      WORKPLACE_ADDRESS: "",
    };
  };
  const self_data = proposalData?.proposer_details;
  const isOptedForMedPractDiscount =
    product?.INSURANCE_SLUG === "niva_bupa" &&
    quote_data?.ADD_ONS?.some(
      (item) =>
        item.ADD_ON_SLUG === "medicalPractDiscount" ||
        item.ADD_ON_SLUG === "MedicalPractDiscount"
    );
  const ref = useRef(null);
  const [abhaId, setAbhaId] = useState<any>();
  if (isProposalLoading) return <></>;
  const initialValues =
    members?.reduce((prev, member) => {
      return {
        ...prev,
        [member.count ? member.relation + member.count : member.relation]:
          member.relation === self_data?.relation
            ? {
                ...initialData(member.gender),
                ...((proposalData?.insurer_details &&
                  proposalData?.insurer_details[member.relation]) ||
                  {}),
                ...self_data,
                relation: member.relation,
              }
            : {
                ...initialData(member.gender),
                relation: member.relation,
                dob: moment()
                  .subtract(member.age, "years")
                  .format("YYYY-MM-DD"),
                ...(proposalData?.insurer_details
                  ? proposalData?.insurer_details[
                      member.count
                        ? member.relation + member.count
                        : member.relation
                    ]
                  : {}),
              },
      };
    }, {}) || {};

  const validationSchema = (member: MembersInterface["relation"]) =>
    yup.object().shape({
      first_name: yup.string().required("First name is required"),
      last_name: yup.string().required("Last name is required"),
      gender: yup.string().required("Gender is required"),
      ...(product?.INSURANCE_SLUG === "niva_bupa" && {
        // ABHA_ID: yup
        //   .string()
        //   .nullable()
        //   .matches(
        //     /^[1-9][0-9]-[0-9]{4}-[0-9]{4}-[0-9]{4}$|^$/,
        //     "Please enter a valid ABHA ID."
        //   )
        //   .min(17, "Please enter a valid value")
        //   .max(17, "Please enter a valid value")
        //   .test("val", "Every member should have unique id", function () {
        //     const values = {}; // Store unique values
        //     for (const key in abhaId) {
        //       if (abhaId.hasOwnProperty(key)) {
        //         const value = abhaId[key];
        //         //@ts-ignore
        //         if (values[value]) {
        //           if (key === member) return false;
        //           else return true; // A duplicate value was found
        //         } else {
        //           //@ts-ignore
        //           values[value] = true;
        //         }
        //       }
        //     }
        //     return true; // No duplicate values were found
        //   }),
        // ,
        is_medical_practitioner: yup.string(),
        MD_PRACTIONARY_ID: yup
          .string()
          .nullable()
          .when("is_medical_practitioner", {
            is: "Y",
            then: yup.string().required("Practitiony Id is required"),
          }),
        COUNCIL_NAME: yup
          .string()
          .nullable()
          .when("is_medical_practitioner", {
            is: "Y",
            then: yup.string().required("Council Name is required"),
          }),
        WORKPLACE_ADDRESS: yup
          .string()
          .nullable()
          .when("is_medical_practitioner", {
            is: "Y",
            then: yup.string().required("Workplace Address is required"),
          }),
      }),
      dob: yup
        .string()
        .required("Date of birth is required")
        .test(
          "dob",
          member === "self" || member === "spouse"
            ? `Age should be between ${product?.ADULT_MIN} and ${product?.ADULT_MAX}`
            : member === "father" || member === "mother"
            ? "Age should be between 40 and 100"
            : `Age should be between ${product?.CHILD_MIN} and ${product?.CHILD_MAX}`,
          (val) => {
            return moment(val, "YYYY-MM-DD").isBetween(
              moment()
                .subtract(
                  member === "self" || member === "spouse"
                    ? product?.ADULT_MAX
                    : member === "father" || member === "mother"
                    ? 100
                    : product?.CHILD_MAX,
                  "years"
                )
                .subtract(1, "year"),
              moment().subtract(
                member === "self" || member === "spouse"
                  ? product?.ADULT_MIN
                  : member === "father" || member === "mother"
                  ? 40
                  : product?.CHILD_MIN,
                "years"
              )
            );
          }
        ),
      feet: yup.string().required("Height is required"),
      inches: yup.string().required("Height is required"),
      weight: yup
        .string()
        .required("Weight is required")
        .test("weight", "Max Weight allowed is 180", (val) => {
          if (product?.INSURANCE_SLUG !== "star") return true;
          else return Number(val) <= 180;
        })
        .test(
          "weight",
          "Please enter a valid Weight",
          (val) => Number(val) > 0
        ),
      occupation: yup.string().required("Occupation is required"),
    });
  const validateMembers =
    members?.reduce((prev, member) => {
      return {
        ...prev,
        [member.count ? member.relation + member.count : member.relation]:
          validationSchema(member.relation),
      };
    }, {}) || {};

  let membersObj: any = {};
  members?.forEach((member) => {
    membersObj[
      member.count ? member.relation + member.count : member.relation
    ] = member;
  });

  if (!members) return null;
  if (activeProposal !== (isCKYC ? 2 : 1)) return null;
  return (
    <>
      <FormLabel label="Tell us the details about members to be insured!" />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateMembers)}
        onSubmit={(values) => {
          const details = Object.values(values);
          const is_any_medical_practitioner = details.some(
            (item: any) => item.is_medical_practitioner === "Y"
          );
          if (!is_any_medical_practitioner && isOptedForMedPractDiscount) {
            setShowMedPractWarning(true);
            //@ts-ignore
            ref?.current?.scrollIntoView(true);
            return;
          }
          saveForm({
            insurer_details: values,
            from_screen: "insurer",
          });
          dispatch(
            setProposalData({
              insurer_details: values,
            })
          );
        }}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Grid container spacing={2}>
              {isSaving && <HeartLoader message={""} />}
              <Grid
                item
                ref={ref}
                xs={12}
                sx={{
                  display: !showMedPractWarning ? "none" : "inline-block",
                }}
              >
                <Alert id="medPractDisc" severity="error">
                  At least one of the member should be a medical practitioner
                </Alert>
              </Grid>

              {members.map((member, index) => {
                const key_name = member.count
                  ? member.relation + member.count
                  : member.relation;
                return (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        color="text.secondary"
                        textTransform={"capitalize"}
                        fontWeight="500"
                      >
                        {member.relation}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        label="First Name"
                        name={`${key_name}.first_name`}
                        onInput={(e) => {
                          allowOnlyAlphabets(e);
                          toCapitalize(e);
                        }}
                        readOnly={member.relation === self_data?.relation}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="Middle Name (Optional)"
                        name={`${key_name}.middle_name`}
                        onInput={(e) => {
                          allowOnlyAlphabets(e);
                          toCapitalize(e);
                        }}
                        readOnly={member.relation === self_data?.relation}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        label="Last Name"
                        name={`${key_name}.last_name`}
                        onInput={(e) => {
                          allowOnlyAlphabets(e);
                          toCapitalize(e);
                        }}
                        readOnly={member.relation === self_data?.relation}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Select
                        label="Gender"
                        name={`${key_name}.gender`}
                        options={genderOptions}
                        readOnly
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DateComp
                        label="Date of Birth"
                        name={`${key_name}.dob`}
                        readOnly={member.relation === self_data?.relation}
                        maxDate={moment().subtract(
                          ["self", "spouse", "father", "mother"].includes(
                            member.relation
                          )
                            ? product?.ADULT_MIN
                            : product?.CHILD_MIN,
                          "years"
                        )}
                        minDate={moment().subtract(
                          ["self", "spouse", "father", "mother"].includes(
                            member.relation
                          )
                            ? product?.ADULT_MAX
                            : product?.CHILD_MAX,
                          "years"
                        )}
                        defaultValue={moment().subtract(member.age, "years")}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Select
                        options={occupation_list || []}
                        label="Occupation"
                        name={`${key_name}.occupation`}
                      />
                    </Grid>
                    {(product?.INSURANCE_SLUG === "niva_bupa" ||
                      product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure") && (
                      <InputSideEffect
                        effect={() => {
                          setAbhaId(
                            Object.keys(values).reduce((prev, curr) => {
                              //@ts-ignore
                              return { ...prev, [curr]: values[curr]?.ABHA_ID };
                            }, {})
                          );
                        }}
                        //@ts-ignore
                        dependencies={[values[key_name]?.ABHA_ID]}
                      >
                        <Grid item md={6} xs={12}>
                          <TextInput
                            label={
                              <>
                                ABHA ID{" "}
                                <Typography
                                  fontSize="10px"
                                  display="inline-block"
                                >
                                  (
                                  <a
                                    target="_blank"
                                    href="https://direct.nivabupa.com/HowToCreateAABHA"
                                  >
                                    How to generate ABHA id?
                                  </a>
                                  )
                                </Typography>
                              </>
                            }
                            name={`${key_name}.ABHA_ID`}
                            onInput={(e) => {
                              let value = e.target.value.split("-").join("");
                              value = value.replace(/[^0-9]/g, "");
                              const regex =
                                /(^[0-9]{1,2})([0-9]{0,4})([0-9]{0,4})([0-9]{0,4}$)/;

                              value = value.replace(
                                regex,
                                (match, ...group) => {
                                  if (group[3]) {
                                    return (
                                      group[0] +
                                      "-" +
                                      group[1] +
                                      "-" +
                                      group[2] +
                                      "-" +
                                      group[3]
                                    );
                                  }
                                  if (group[2]) {
                                    return (
                                      group[0] + "-" + group[1] + "-" + group[2]
                                    );
                                  }
                                  if (group[1]) {
                                    return group[0] + "-" + group[1];
                                  }
                                  return group[0];
                                }
                              );
                              e.target.value = value;
                            }}
                            maxLength={17}
                          />
                        </Grid>
                      </InputSideEffect>
                    )}
                    <Grid item md={6} xs={12}>
                      <Height
                        name={key_name}
                        readOnly={member.relation === self_data?.relation}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextInput
                        label="Weight"
                        name={`${key_name}.weight`}
                        onInput={allowOnlyNumbers}
                        maxLength={3}
                        readOnly={member.relation === self_data?.relation}
                      />
                    </Grid>
                    {isOptedForMedPractDiscount && (
                      <>
                        <MedPractSection key_name={key_name} />
                      </>
                    )}
                  </>
                );
              })}

              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    background: "#FF6024",
                    "&:hover": { background: "#f26d02" },
                  }}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Proceed to Medical Details
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InsurerForm;
